<template>
	<div>
		<v-row>
			<v-col>
				<s-toolbar
					dark
					:color="'#BAB6B5'"
					class="mr-4 ml-2"
					style="display: flex"
					print
					@print="printQR()"
				></s-toolbar>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<v-data-table
					:items="palletsprocess"
					item-key="LlpID"
					dense
					:headers="headers"
					:items-per-page="15"
					disable-sort
					v-model="selected"
					@click:row="rowClickSend"
				>
					
				</v-data-table>
			</v-col>
		</v-row>
		
		
		<v-card>
			<v-row justify="center" cols="12" style="display: none">
				<v-col cols="12" v-for="item in selected">
					
					<qr-code
						:text="
							item.PprID +
								',' +
								item.LlpID +
								',' +
								item.TypeProcess +
								',' +
								item.PprOrigin +
								',' +
								item.PprOriginID +
								',' +
								item.PprQuantityJaba
						"
						error-level="H"
						:size="500"
						hidden
					>
					</qr-code>
					<br />
				</v-col>
			</v-row>
		</v-card>
	</div>
</template>

<script>
	import _sPalletProcessService from "@/services/FrozenProduction/PalletProcessService.js";
	import qrCode from "vue-qr-generator";

	export default {
		components: {
			qrCode,
		},

		data() {
			return {
				config:{
					model:{
						PprID: "ID",
						print: "string"
					},
					service: _sPalletProcessService,
					
				},
				headers: [
					{text: "ID", value: "PprID"},
					{text: "Cliente", value: "CustomerName"},
					{text: "Linea Proceso", value: "TypeProcessName"},
					{text: "Pallet", value: "LlpID"},
					{text: "Tipo Selección", value: "TypeSelectionFrozenName"},
					{text: "Categoría", value: "TypeFrozenCategoryName"},
					{text: "Tipo Cultivo", value: "TypeCropName"},
					{text: "Cultivo", value: "TypeCultiveName"},
					{text: "Variedad", value: "VrtName"},
				],
				selected: [],
				palletsprocess: [],
				itemsDetail: []
			}
		},

		methods: {
			printQR() {
				var imgAll = document.querySelector("img");
				console.log('imgAll ', imgAll);
				var Pagelink = "about:blank";
				var pwa = window.open(Pagelink, "_new");
				pwa.document.open();
				pwa.document.write(
					"<html><head><scri" +
						"pt>function step1(){\n" +
						"setTimeout('step2()', 10);}\n" +
						"function step2(){window.print();window.close()}\n" +
						"</scri" +
						"pt><style  media='print'>  @media print  { @page { margin-left: 4cm; {} </style></head><body onload='step1()'> \n" +
						"<div class='col col-12 justify-center'>",
				);

				
				/* imgAll.forEach(element => { */
					pwa.document.write(
						"<div class='col col-4' style='margin: 20px 20px 20px 20px;'><img alt='Scan me!' style='display: block;'  src='" +
							imgAll.currentSrc +
							"' /></div>",
					);

					pwa.document.write("<br><br><div ><table border='0'>");
					pwa.document.write(
								"<tr><td colspan='7'>Datos Generales: " + 
								"<tr>" +
								"<td>" + "<b>"+this.selected[0].LlpID + "</b>"+
								"</td><td> ||" + "<b>"+this.selected[0].TypeProcessName + "</b>"+
								"</td><td> ||" + "<b>"+this.selected[0].CustomerName + "</b>"+
								"</td><td> || " + "<b>"+this.selected[0].VrtName + "</b>"+
								"</td><td> || " + "<b>"+this.selected[0].TypeCropName + "</b>"+
								"</td><td> || " + "<b>"+this.selected[0].TypeSelectionFrozenName + "</b>"+
								"</td><td> || " + "<b>"+this.selected[0].TypeFrozenCategoryName + "</b>"+
								" </td></tr>",
					);
					pwa.document.write("</table></div>");

					this.itemsDetail.forEach(detail => {
						
						pwa.document.write("<br><br><div ><table border='0'>");

						pwa.document.write(
							"<tr><td>Pallet: </td><td>" + detail.LlpID + "</td></tr>" +
							"<tr><td>Cliente: </td><td>" + detail.CustomerName +"</td></tr>"
						);

						pwa.document.write(
							"<tr><td>Cantidad Jabas: </td><td>" + detail.PprQuantityJaba +"</td></tr>" +
							"<tr><td>Tipo proceso: </td><td>" + detail.TypeProcessName + "</td></tr>" +
							"<tr><td>Tipo Bruto: </td><td>" + detail.PprWeightGross + "</td></tr>" +
							"<tr><td>Peso Neto: </td><td>" + detail.PprWeightNet + "</td></tr>" +
							"<tr><td>Tipo Bruto: </td><td>" + detail.PprWeightGross + "</td></tr>" +
							"<tr><td>Tipo Selección: </td><td>" + detail.TypeSelectionFrozenName + "</td></tr>" +
							"<tr><td>Categoría: </td><td>" + detail.TypeFrozenCategoryName + "</td></tr>" +
							"<tr><td>Tipo Cultivo: </td><td>" + detail.TypeCropName + "</td></tr>" +
							"<tr><td>Tipo Cultivo: </td><td>" + detail.TypeCultiveName + "</td></tr>" +
							"<tr><td>Variedad: </td><td>" + detail.VrtName + "</td></tr>" 
						);

						pwa.document.write("</table></div>");
					});

				/* }); */

				

				pwa.document.write("</div></body></html>");
				pwa.document.close();
			},

			getPalletProcess()
			{
				_sPalletProcessService
				.list(this.$fun.getUserID())
				.then((resp) => {
					if (resp.status == 200) {
						this.palletsprocess = resp.data;
                        console.log("🚀 ~ file: PrintQR.vue ~ line 171 ~ .then ~ this.palletsprocess", this.palletsprocess)
					}
				});
			},

			rowClickSend(item, row) {
				this.selected = [item];
                console.log("🚀 ~ file: PrintQR.vue ~ line 178 ~ rowClickSend ~ this.selected", this.selected)
				this.itemsDetail = this.selected;

				/* if(this.selected.length > 0) {
					let obj = {
						PprID: this.selected[0].PprID
					}
					_sPalletProcessService.printqr(obj, this.$fun.getUserID()).then(resp => {
						if (resp.status == 200) {
							this.itemsDetail = resp.data;
							console.log('dett ', this.itemsDetail);
							
						}
					});
				} */

			},
		},

		created () {
			this.getPalletProcess();
		},
	}
</script>