var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',[_c('v-col',[_c('s-toolbar',{staticClass:"mr-4 ml-2",staticStyle:{"display":"flex"},attrs:{"dark":"","color":'#BAB6B5',"print":""},on:{"print":function($event){return _vm.printQR()}}})],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"items":_vm.palletsprocess,"item-key":"LlpID","dense":"","headers":_vm.headers,"items-per-page":15,"disable-sort":""},on:{"click:row":_vm.rowClickSend},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1),_c('v-card',[_c('v-row',{staticStyle:{"display":"none"},attrs:{"justify":"center","cols":"12"}},_vm._l((_vm.selected),function(item){return _c('v-col',{attrs:{"cols":"12"}},[_c('qr-code',{attrs:{"text":item.PprID +
							',' +
							item.LlpID +
							',' +
							item.TypeProcess +
							',' +
							item.PprOrigin +
							',' +
							item.PprOriginID +
							',' +
							item.PprQuantityJaba,"error-level":"H","size":500,"hidden":""}}),_c('br')],1)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }